﻿div.extended-search {
    > .extended-search-trigger-open, > .extended-search-trigger-close {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        > div.extended-search-body {
            display: none;
        }

        > .extended-search-trigger-open {
            display: block;
        }

        &.extended-search-toggle {
            > div.extended-search-body {
                display: block;
            }

            > .extended-search-trigger-open {
                display: none;
            }


            > .extended-search-trigger-close {
                display: block
            }
        }
    }
}