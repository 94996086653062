﻿/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/content/fonts/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('/content/fonts/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/content/fonts/open-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/content/fonts/open-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/content/fonts/open-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/content/fonts/open-sans-v18-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}
/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/content/fonts/open-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('/content/fonts/open-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/content/fonts/open-sans-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('/content/fonts/open-sans-v18-latin-600.woff') format('woff'), /* Modern Browsers */
    url('/content/fonts/open-sans-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/content/fonts/open-sans-v18-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}
/* open-sans-800 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('/content/fonts/open-sans-v18-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''), url('/content/fonts/open-sans-v18-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/content/fonts/open-sans-v18-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('/content/fonts/open-sans-v18-latin-800.woff') format('woff'), /* Modern Browsers */
    url('/content/fonts/open-sans-v18-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/content/fonts/open-sans-v18-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}
