﻿@import "_base.scss";

.basket-add-group {
    .select-bestelleinheit {
        width: 40% !important;
    }
    .input-group {
        width: 60%;
    }
    button + button {
        border-left: 1px solid darken($primary, 7.5%);
    }
}