﻿@import "_base.scss";

$m-nav-bgcolor: $primary;
$m-nav-color: white;
$m-nav-fontsize: 1.3rem;
$m-nav-border-color: white;
$m-nav-line-height: 50px;
$m-nav-width: 80%;
$m-nav-max-width: 450px;

.m-nav-block {
	position: fixed;
	background-color: black;
	opacity: 0.2;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1999;
}

.m-nav {
    display: none;
    background-color: $m-nav-bgcolor;
    position: fixed;
    z-index: 2000;
    top: 0;
    bottom: 0;
    width: $m-nav-width;
    max-width: $m-nav-max-width;
    overflow-y: scroll;
    -webkit-box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.6);
    -moz-box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.6);
    box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.6);

    .btn-close {
        color: $m-nav-color;
        float: right;
        margin-top: 5px;
        width: $m-nav-line-height;
        text-align: center;
    }

    div.title {
        color: $m-nav-color;
        font-size: $m-nav-fontsize;
        padding-left: 10px;
    }

    > ul {
        margin-top: 45px;
    }

    > div.title + ul {
        margin-top: 20px;
    }

    ul {
        list-style-type: none;
        padding: 0;

        li {
            border-top: solid 1px $m-nav-border-color;
            line-height: $m-nav-line-height;

            a {
                color: $m-nav-color;
                display: block;
                font-size: $m-nav-fontsize;
                text-decoration: none;
            }

            &.nav-menu {
                > .menu-arrow + a {
                    margin-right: $m-nav-line-height;
                }

                > .menu-arrow {
                    float: right;
                    color: white;
                    //font-family: 'Font Awesome 5 Pro';
                    //font-weight: 400;
                    cursor: pointer;
                    //width: $m-nav-line-height;
                    text-align: center;
                    border-left: solid 1px $m-nav-border-color;
                    font-size: $m-nav-fontsize;
                    //width: 23px;
                    //height: 23px;
                    width: $m-nav-line-height;
                    height: $m-nav-line-height;
                    background: url('~@fortawesome/fontawesome-pro/svgs/solid/chevron-down.svg?fill=%23fff') no-repeat 20px 20px;
                    background-size: 20px 20px;
                    background-position: center;
                    //vertical-align: middle;
                    /*&::before {
                        //content: "\f078";
                        content: ' ';
                        background: url('~@fortawesome/fontawesome-pro/svgs/solid/chevron-down.svg?fill=%23fff') no-repeat;
                        background-size: 10px 16px;
                        width: $m-nav-line-height;
                        height: $m-nav-line-height;
                        background-position: 0 2px;
                        vertical-align: middle;
                    }*/
                }

                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }

                > ul {
                    display: none;
                }

                &.open {
                    > ul {
                        display: block;
                    }

                    > .menu-arrow {
                        background-image: url('~@fortawesome/fontawesome-pro/svgs/solid/chevron-up.svg?fill=%23fff');
                        /*&:before {
                            content: "\f077";
                        }*/
                    }
                }
            }
        }
    }

    > ul > li:last-child {
        border-bottom: solid 1px $m-nav-border-color;
    }

    > ul > li > a {
        padding-left: 10px;
    }

    > ul > li > ul > li > a {
        padding: 6px 0 6px 30px;
        line-height: 30px;
    }

    > ul > li > ul > li > ul > li > a {
        padding-left: 50px;
    }

    > ul > li > ul > li > ul > li > ul > li > a {
        padding-left: 70px;
    }

    > ul > li > ul > li > ul > li > ul > li > ul > li > a {
        padding-left: 90px;
    }

    > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > a {
        padding-left: 110px;
    }

    > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > a {
        padding-left: 130px;
    }
}

body {
	overflow-y: scroll;
}

html.m-nav-open {
	overflow: hidden;
	position: relative;
}

html.m-nav-opening, html.m-nav-closing {
	overflow: hidden;
	position: relative;

	.m-nav {
		-webkit-transition: none 0.4s ease;
		-moz-transition: none 0.4s ease;
		-ms-transition: none 0.4s ease;
		-o-transition: none 0.4s ease;
		transition: none 0.4s ease;
		-webkit-transition-property: top, right, bottom, left, -webkit-transform;
		-moz-transition-property: top, right, bottom, left, -moz-transform;
		-ms-transition-property: top, right, bottom, left, -ms-transform;
		-o-transition-property: top, right, bottom, left, -o-transform;
		transition-property: top, right, bottom, left, transform;
	}
}