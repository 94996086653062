﻿// Collapse: Text anpassen für expanded / collapsed
[data-toggle="collapse"] {
	&.collapsed .if-not-collapsed {
		display: none;
	}

	&:not(.collapsed) .if-collapsed {
		display: none;
	}
}

// Button mit Font-Awesome spinner
.btn-loading-indicator:not(.btn-loading) {
    > .loading-indicator {
        display: none;
    }
}

.text-decoration-none {
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

@mixin text-truncate-multiline($lines) {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
}

.text-truncate-multiline-2 {
	@include text-truncate-multiline(2);
}

//Korrektur InputGroup mit Validation Message
.input-group + .field-validation-error.invalid-feedback {
    display: block;
}

//Bootstrap Select Ticks left
.bootstrap-select.show-tick.ticks-left .dropdown-menu .selected span.check-mark {
    right: unset;
    left: 28px;
}

.bootstrap-select.show-tick.ticks-left .dropdown-menu li a span.text {
    margin-right: unset;
    margin-left: 28px;
}

//Bootstrap Select default border color
.bootstrap-select button.dropdown-toggle {
    border-color: #ced4da;
}

//Formular Required mit *
form[role="form"] .form-group > label.is-required:after {
    content: ' *';
}

input[type="number"].no-spin-button::-webkit-outer-spin-button,
input[type="number"].no-spin-button::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"].no-spin-button {
    -moz-appearance: textfield;
}