﻿$d-nav-menu-mobile-breakpoint: md;
$d-nav-menu-level1-fontsize: 1.2rem;
$d-nav-menu-level1-padding: 10px;
$d-nav-menu-level1-color: #2B2B2B;
$d-nav-menu-level1-bgcolor: transparent;
$d-nav-menu-level1-open-color: $d-nav-menu-level1-color;
$d-nav-menu-level1-open-bgcolor: lighten($d-nav-menu-level1-bgcolor, 10%);

$d-nav-menu-color: $d-nav-menu-level1-open-color;
$d-nav-menu-bgcolor: $d-nav-menu-level1-bgcolor;
$d-nav-menu-line-height: 2rem;
$d-nav-menu-fontsize: 1.25rem;
$d-nav-menu-border-color: transparent;

// **************************************************************
// * Navigation Level 1 - Menu
// **************************************************************
ul.d-nav-menu-toplevel {
    @include media-breakpoint-down($d-nav-menu-mobile-breakpoint) {
        display: none;
    }

    background-color: $d-nav-menu-level1-bgcolor;
    /*width: 100%;*/
    list-style-type: none;
    padding: 0;
    display: inline-block;
    margin: 0;
    line-height: $d-nav-menu-level1-fontsize;

    > li {
        float: left;
        font-size: $d-nav-menu-level1-fontsize;
        display: inline-block;

        &:hover {
            background-color: #fff;
        }

        > a {
            padding: $d-nav-menu-level1-padding;
            color: $d-nav-menu-level1-color;
            text-decoration: none;
            display: block;
            white-space: nowrap;
            font-size: 1.08rem;
        }

        &.nav-menu {

            > .menu-arrow + a {
                margin-right: 40px;
            }

            > .menu-arrow {
                float: right;
                cursor: pointer;
                width: 20px;
                height: 20px;
                border-left: solid 1px $d-nav-menu-border-color;
                background: url('~@fortawesome/fontawesome-pro/svgs/light/chevron-down.svg') no-repeat;
                background-size: 20px 20px;
                background-position: center;
                transition: transform 0.3s;
            }

            &:after {
                content: '';
                display: block;
                clear: both;
            }

            &.open {
                background-color: #fff;

                a {
                    color: $d-nav-menu-level1-open-color;
                }

                > .menu-arrow {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

// **************************************************************
// * Navigation Level 2 und weitere
// **************************************************************
.d-nav-menu-items {
    display: none;
    background-color: $d-nav-menu-bgcolor;
    position: absolute;
    z-index: 2000;

    @include media-breakpoint-down($d-nav-menu-mobile-breakpoint) {
        display: none !important;
    }

    &.visible {
        transition: height 0.2s ease;
        display: block;
        overflow: hidden;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        background-color: #fff;

        li {
            border-top: solid 1px $d-nav-menu-border-color;
            line-height: $d-nav-menu-line-height;
            min-width: 250px;

            &:hover {
                background-color: #EFEFEF;
            }

            a {
                color: $d-nav-menu-color;
                display: block;
                text-decoration: none;
                padding-right: 20px;
                border-bottom: solid 1px #EFEFEF;
                font-size: 0.92rem;
            }

            &.nav-menu {

                &:hover {
                    background-color: white;
                }

                > .menu-arrow + a {
                    margin-right: $d-nav-menu-line-height;
                }

                > .menu-arrow {
                    float: right;
                    cursor: pointer;
                    width: $d-nav-menu-line-height;
                    height: $d-nav-menu-line-height;
                    border-left: solid 1px $d-nav-menu-border-color;
                    background: url('~@fortawesome/fontawesome-pro/svgs/light/chevron-down.svg') no-repeat;
                    background-size: 20px 20px;
                    background-position: center;
                    transition: transform 0.3s;
                }

                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }

                > ul {
                    display: none;
                }

                &.open {
                    > ul {
                        display: block;
                    }

                    > .menu-arrow {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    //> ul > li:first-child {
    //	border-top: none;
    //}
    > ul > li > a {
        padding-left: 20px;
    }

    > ul > li > ul > li > a {
        padding-left: 40px;
    }

    > ul > li > ul > li > ul > li > a {
        padding-left: 60px;
    }

    > ul > li > ul > li > ul > li > ul > li > a {
        padding-left: 80px;
    }

    > ul > li > ul > li > ul > li > ul > li > ul > li > a {
        padding-left: 100px;
    }

    > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > a {
        padding-left: 120px;
    }

    > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > a {
        padding-left: 140px;
    }
}
