﻿.checkout-header {
    .info-fact {
        margin-bottom: 1rem;

        icon {
            display: none;
        }

        > span:first-child {
            display: block;
            line-height: 1;
            font-weight: 400;
            font-size: 0.9rem;
        }

        > span + * {
            margin: 0;
            //font-size: 1.3em;
            //font-weight: 300;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }
}