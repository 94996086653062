﻿@import "_base.scss";

.artikel-detail-photos {
    img {
        max-height: 500px;
    }

    @include media-breakpoint-up(md) {
        img {
            max-height: inherit;
        }
    }
}
.artikel-infos {
    margin-bottom: 2.5rem;

    dl {
        margin-bottom: 0;
    }
    dt i {
        width: 20px;
    }

    .price-wrapper {
        margin-bottom: .5rem;

        .currency {
            font-size: 1.5rem;
        }
        .price {
            font-size: 2.5rem;
        }
        .tax {
            font-size: 0.9rem;
        }
    }
}
.description {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 2rem;
}
.weininfos {
    background-color: #f5f4f3;

    .row {
        > div {
            padding-left: 25px;
            padding-right: 25px;
        }
    }
}

.fact-box {
    padding: 1.5rem 1.5rem 0.5rem;
    background-color: #f5f4f3;

    h4 {
        border-bottom: 1px solid #dad5d0;
        padding-bottom: 1rem;
        margin-bottom: 1.25rem;
    }
}