﻿@import "_base.scss";

html, body {
    font-size: 17px;
    font-family: $font-family-sans-serif;
}

b, strong {
    font-weight: $font-weight-bold;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-title;
}

h1 {
    margin-bottom: 2rem;
    font-weight: 600;
}

h4 {
    font-weight: 600;
}

.nav.nav-clean {
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2);

    .nav-item {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;

        .nav-link {
            padding-left: 0;
            padding-right: 0;

            &.active {
                border-bottom: 3px solid $primary;
            }
        }
    }
}

.container-xs-fullwidth {
    @include media-breakpoint-down(xs) {
        padding: 0;
    }
}

#page-content {
    position: relative;
}

#header {
    .header-bg {
        /*background-color: $dark;*/
    }

    > .container > .row > div:nth-child(2) {
        padding-top: 0rem;
    }

    .jubilaeum-banner {
        @include media-breakpoint-only(md) {
            height: 140px;
        }

        @include media-breakpoint-down(sm) {
            height: 125px;
        }

        @media (max-width: 500px) {
            height: 115px;
        }

        @media (max-width: 450px) {
            height: 105px;
        }

        @media (max-width: 400px) {
            height: 95px;
        }
    }

    .logo {
        position: absolute;
        bottom: 22px;
        right: 12.5%;
        padding-right: 15px;

        img {
            width: 420px;
        }

        @include media-breakpoint-only(lg) {

            img {
                width: 400px;
            }
        }

        @include media-breakpoint-only(md) {
            bottom: 0;
            right: 0;

            img {
                width: 320px;
            }
        }

        @include media-breakpoint-only(sm) {
            bottom: 0;
            right: 0;

            img {
                width: 280px;
            }
        }

        @include media-breakpoint-only(xs) {
            bottom: 0;
            right: 0;

            img {
                width: 50vw;
            }
        }
    }

    .mainnavigation-bg {
        background-color: $grey;
    }

    .header-infos {
        a {
            line-height: 2rem;
            font-size: 0.9rem;
        }
    }
}

#header-image {
    position: relative;

    #input-group-search-header {
        position: absolute;
        bottom: 10px;
        right: 25px;
        width: 200px;

        .input-group-text {
            background-color: white;
            border-right: none;
        }

        .form-control {
            border-left: none;

            &:focus {
                box-shadow: none;
            }
        }
    }
}

#header-desktop {
    margin-top: 4px;
}

.breadcrumb-wrapper {
    background-color: $primary;
}

.breadcrumb a {
    color: white;
}

.breadcrumb-item + .breadcrumb-item::before {
    //font-family: "Font Awesome 5 Pro";
    //font-weight: 300;
    content: '';
    background: url('~@fortawesome/fontawesome-pro/svgs/light/chevron-right.svg?fill=%23fff') no-repeat;
    background-size: 10px 16px;
    width: 23px;
    height: 23px;
    background-position: 0 2px;
    vertical-align: middle;
}

.back-button {
    left: 0;
    top: -45px;
    z-index: 1;

    @include media-breakpoint-up(sm) {
        position: absolute;
    }
}

.info-fact {
    margin-bottom: 1rem;

    icon {
        display: none;
    }

    > span:first-child {
        display: block;
        line-height: 1;
        font-weight: 400;
        font-size: 0.9rem;
    }

    > span + * {
        margin: 0;
        font-size: 1.3em;
        font-weight: 300;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}



.table thead th {
    border-top: 0;
}

.table-clean {
    th, td {
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}

.list-table {
    margin-bottom: $spacer;

    .list-head, .list-row {
        // reset grid margins
        margin-left: 0;
        margin-right: 0;

        > * {
            padding: ($table-cell-padding * 0.75);
            vertical-align: top;
        }
    }

    .list-head {
        font-weight: bold;

        > * {
            vertical-align: bottom;
            border-bottom: $table-border-width solid $table-border-color;
            align-self: flex-end;
        }
    }

    .list-row {
        > * {
            border-top: $table-border-width solid $table-border-color;
        }
    }

    a.list-row {
        color: $body-color;

        &:hover {
            text-decoration: none;
            background-color: $gray-100;
        }
    }
}

footer#footer {
    background-color: $grey;
    font-size: 0.8rem;

    .copyright {
        background-color: $dark;
        font-size: 0.7rem;
        color: white;
    }

    .footer-titel {
        font-weight: bold;
        margin-bottom: 15px;
    }

    svg {
        color: $dark;
    }
}

#wrapper {
    .pagetitel {
        font-size: 32px;
        text-align: center;
        border-bottom: 1px solid black;
        padding-bottom: 30px;
    }

    @include media-breakpoint-down(sm) {
        .pagetitel {
            font-size: 28px;
        }
    }
}

.wrapper-searchfields {
    background-color: $lightgrey;
}

.serie-list-item {
    min-height: 390px;
    border: 1px solid $lightgrey;

    .serie-list-image-wrapper {
        height: 220px;
    }

    img {
        max-height: 100%;
    }

    .text-wrapper {
        background-color: $lightgrey;

        a {
            color: $red;
        }
    }
}

.artikel-detail {
    .zusatztext, .zusatzinformationen {
        font-size: 14px;
    }

    .table-artikeldetail {
        font-size: 14px;

        th, td {
            padding: 0.3rem;
        }

        thead {
            th:first-of-type {
                min-width: 114px;
            }
        }

        tr.odd {
            background-color: rgba(0,0,0,.05);
        }

        tr.zusatztext td {
            border-bottom: 0;
        }

        tr.zusatztext + tr td {
            border-top: 0;
        }
    }
}

.videowrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.footer-wrapper {
    justify-content: space-between;
    flex-direction: row;

    @include media-breakpoint-down(xs) {
        justify-content: center;
        flex-direction: column;
    }

    .footer2 {
        div {
            padding: 0 8px;
        }

        div:first-child {
            padding-left: 0;
        }

        div:not(:last-child) {
            border-right: 1px solid white;
        }
    }
}
