﻿@import "_base.scss";

.list-bestellpositionen {
    .list-row-mwst > div {
        padding-bottom: 0;
    }
    .list-row-mwst + .list-row-mwst > div {
        padding-top: 0;
        border-top: 0;
    }
}

.button-size {
    padding: $btn-padding-y $btn-padding-x;
}

