﻿//Project variables

//Bootstrap Variable overrides
$primary: #333333;
$secondary: #FFD900;
$dark: #2B2B2B;
$grey: #EFEFEF;
$lightgrey: #EFEDE9;
$red: #D90000;

$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-title: 'Open Sans', serif;
/*
$font-size-base: 1rem;
$line-height-base: 1.6;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$text-muted: #9a9898;

$headings-font-weight: 300;
$h1-font-size: $font-size-base * 2.2;
$h2-font-size: $font-size-base * 1.9;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
*/
$carousel-control-color: $primary;

// Breadcrumbs
/*$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 1rem;
$breadcrumb-item-padding: 1rem;
$breadcrumb-margin-bottom: 3rem;
$breadcrumb-bg: $primary;
$breadcrumb-divider-color: white;
$breadcrumb-active-color: rgba(255, 255, 255, 0.65);
$breadcrumb-divider: quote("\f054");*/

@import "../../node_modules/bootstrap/scss/_functions.scss";
@import "../../node_modules/bootstrap/scss/_variables.scss";
@import "../../node_modules/bootstrap/scss/_mixins.scss";

//Font Awesome Library
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/_variables.scss";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/_mixins.scss";

//Bootstrap Select
$input-color-placeholder: $input-placeholder-color;
