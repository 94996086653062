﻿/* carousel with multiple slides */
#carouselMultiItem {

    .carousel-inner {

        .carousel-item {

            .row {
                .col-md-4 {

                    .card {
                        border-radius: 0;
                        text-align: left !important;

                        .card-header {
                            border-bottom: 0;
                            border-radius: 0;
                            padding: 8px 0 8px 46px;
                            background-color: #333333;
                            color: #EDEDED;
                            font-size: 20px;
                            text-transform: uppercase;
                            background-image: url('../images/infobox-icon.png');
                            background-repeat: no-repeat;
                            background-position: 10px;
                        }
                    }
                }
            }
        }
    }
}

.controls {
    position: absolute;
    left: calc(50% - 67px);
    bottom: 0;

    @media (max-width: 768px) {
        bottom: -30px;
    }

    .fa-chevron-circle-left {
        margin-right: 10px;
    }

    .fa-chevron-circle-right {
        margin-left: 10px;
    }

    .fa-circle {
        color: #fce600;
        margin: 8px 3px;
    }
}