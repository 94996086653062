﻿@import "_base.scss";

.btn-form {
    @include button-variant($input-border-color, $input-border-color);
}

.btn-input-group {
    @include button-variant($input-group-addon-bg, $input-group-addon-border-color);
}

.btn-outline-form {
    @include button-outline-variant($input-border-color, color-yiq($input-border-color), $input-border-color, $input-border-color);
    color: $body-color;
}
