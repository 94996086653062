﻿.dropdown.multiselect {
    > .dropdown-menu {
        min-width: 320px;
        padding-top: 0;

        > .inner-scroller {
            overflow-x: hidden;
            overflow-y: scroll;
            max-height: 400px;
            min-height: 150px;
        }

        div.header {
            color: color-yiq($primary);
            background-color: $primary;
            border-bottom: 1px solid $dropdown-divider-bg;
        }

        div.searchbox {
            margin-top: $dropdown-divider-margin-y;
        }
    }

    @include media-breakpoint-down(sm) {
        > .dropdown-menu.show {
            position: fixed !important;
            left: 10px !important;
            right: 10px !important;
            top: 10px !important;
            bottom: 10px !important;
            will-change: unset !important;
            transform: none !important;
            display: flex;
            flex-direction: column;

            > .inner-scroller {
                max-height: unset;
                min-height: unset;
                height: 100%;
            }
        }
    }
}

/*@include media-breakpoint-down(sm) {
    body.multiselect-open {
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.8);
            z-index: $zindex-dropdown - 1;
        }
    }
}*/


body.multiselect-open {
    @include media-breakpoint-down(sm) {
        overflow: hidden;

        &:before {
            background-color: rgba(0,0,0,0.8);
        }
    }

    &:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.05);
        z-index: $zindex-dropdown - 1;
    }
}
