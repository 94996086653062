﻿.dropdown.searchfield-suchbegriff {
    > .dropdown-menu {
        min-width: 320px;
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 400px;

        > button.dropdown-item {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}
